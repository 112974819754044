import { useQuery } from 'react-apollo-hooks';
import query from '../../queries/tracking';

const useTracking = variables => {
  const { data, loading } = useQuery(query, {
    variables,
    skip: Object.keys(variables || {}).length === 0,
  });

  return { data, loading };
};

export default useTracking;
