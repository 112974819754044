import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle';
import SectionSubtitle from '../SectionSubtitle';
import { PRIMARY } from '../../utils/colors';

const TrackingShipping = ({ name }) => (
  <>
    <SectionTitle size="small" color={PRIMARY}>
      Transportadora
    </SectionTitle>
    <SectionSubtitle>{name}</SectionSubtitle>
    <section>
      <p>
        A partir da coleta até a entrega o responsável pela carga é a
        transportadora.
      </p>
      <p>
        Eles nos enviam todas as informações para que você fique sempre bem
        informado(a).
      </p>
      <p>Qualquer dúvida ou problema fale conosco.</p>
    </section>
  </>
);

TrackingShipping.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(TrackingShipping);
