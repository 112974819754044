import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import SectionTitle from '../SectionTitle';
import Marker from '../Timeline/Marker';
import Step from '../Timeline/Step';
import TrackingStatusInfo from './TrackingStatusInfo';
import useTrackingStatus from '../../hooks/useTrackingStatus';

const TrackingStatus = ({
  delivered_at: deliveredAt,
  estimated_delivery_at: estimateDelivery,
  collect_at: collectAt,
  status,
  status_detail: statusDetail,
  delivery_type: deliveryType,
}) => {
  const { title, label, date, statusList, statusIndex } = useTrackingStatus({
    status,
    statusDetail,
    deliveredAt,
    estimateDelivery,
    collectAt,
    deliveryType,
  });
  return (
    <Container>
      <Row className="mt-4 mb-5 pb-3">
        <Col className="text-center">
          {title ? (
            <>
              <SectionTitle>{title}</SectionTitle>
              {date && (
                <h4 className="d-flex justify-content-center align-items-center">
                  {`${label}: ${date}`}
                  <TrackingStatusInfo />
                </h4>
              )}
            </>
          ) : (
            <SectionTitle>{status}</SectionTitle>
          )}
        </Col>
      </Row>
      <Row className="mt-5">
        {statusList.map((item, index) => (
          <Col
            className="d-flex flex-column text-center pl-0 pr-0"
            key={index.toString()}
          >
            <Marker
              checked={index <= statusIndex}
              last={index === statusList.length - 1}
              hasmorecheckeds={index < statusIndex}
            />
            <Step checked={index <= statusIndex} className="mt-2">
              <small>
                {`${index + 1}. ${item.charAt(0).toUpperCase()}${item.substring(
                  1
                )}`}
              </small>
            </Step>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

TrackingStatus.propTypes = {
  delivered_at: PropTypes.string,
  estimated_delivery_at: PropTypes.string,
  collect_at: PropTypes.string,
  status: PropTypes.string,
  status_detail: PropTypes.string,
  delivery_type: PropTypes.string,
};

TrackingStatus.defaultProps = {
  delivered_at: null,
  estimated_delivery_at: null,
  collect_at: null,
  status: null,
  status_detail: null,
  delivery_type: null,
};

export default memo(TrackingStatus);
