import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../SectionTitle';
import SectionSubtitle from '../SectionSubtitle';
import { PRIMARY } from '../../utils/colors';
import TrackingPerson from './TrackingPerson';

const TrackingSender = ({
  name,
  business_name: businessName,
  document,
  city,
  state,
}) => (
  <>
    <SectionTitle size="small" color={PRIMARY}>
      Remetente
    </SectionTitle>
    <SectionSubtitle>{`${city} - ${state}`}</SectionSubtitle>
    <section>
      <TrackingPerson
        name={name}
        businessName={businessName}
        document={document}
      />
    </section>
  </>
);

TrackingSender.propTypes = {
  name: PropTypes.string.isRequired,
  business_name: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default memo(TrackingSender);
