import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import Yup from '../../utils/yup';
import TextField from '../TextField';
import NumberField from '../NumberField';
import useTracking from '../../hooks/useTracking';
import Masks from '../../utils/masks';
import { registerClick, TRACKING_JUMBOTRON } from '../../api/gtm';

const validationSchema = Yup.object().shape({
  code: Yup.string().required('É obrigatório'),
  type: Yup.string(),
  document: Yup.string().when('type', {
    is: 'invoice_number',
    then: Yup.string().required('É obrigatório'),
    otherwise: Yup.string(),
  }),
});

const TrackingForm = ({
  initialValues,
  setData,
  setCurrentTracking,
  setLoading,
  variables,
  setVariables,
}) => {
  const { data = {}, loading } = useTracking(variables);

  const onSubmit = async ({ type, code, document }) => {
    registerClick({ event: TRACKING_JUMBOTRON, code });
    const formatedCode = type === 'code' ? code.replace(/[^\w]/g, '') : code;
    setVariables({ [type]: formatedCode, document });
    if (type === 'code') {
      window.location.search = `search=${formatedCode}`;
    }
  };

  useEffect(() => {
    if (data && Array.isArray(data.fetchTracking)) {
      setData(data.fetchTracking);
      if (data.fetchTracking.length === 1) {
        setCurrentTracking(data.fetchTracking[0]);
      }
    } else {
      setData([]);
      setCurrentTracking(null);
    }
  }, [data, setCurrentTracking, setData, variables]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Formik
      initialValues={{ ...initialValues, type: 'code', document: '' }}
      validateOnBlur
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({ values }) => (
        <Form>
          <Container className="pt-3">
            <Row form>
              <Col xs="12" md="3">
                <FormGroup>
                  <Field
                    component="select"
                    className="form-control-lg form-control"
                    name="type"
                  >
                    <option value="code">Pedido</option>
                    <option value="invoice_number">Nota fiscal</option>
                  </Field>
                </FormGroup>
              </Col>
              {values.type === 'invoice_number' && (
                <Col xs="12" md="4">
                  <NumberField
                    name="document"
                    placeholder="CPF/CNPJ do Destinatário"
                    mask={Masks.cpfOrCnpj}
                  />
                </Col>
              )}
              <Col xs="12" md={values.type === 'code' ? 6 : 3}>
                <TextField
                  name="code"
                  placeholder={
                    values.type === 'code'
                      ? 'Código de rastreio'
                      : 'Número da nota fiscal'
                  }
                />
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={loading}
                    color="primary"
                    block
                    className="d-flex justify-content-center align-items-center"
                  >
                    {loading ? (
                      <>
                        <Spinner size="sm" color="dark" />
                        Procurando
                      </>
                    ) : (
                      'Rastrear'
                    )}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    />
  );
};

TrackingForm.propTypes = {
  initialValues: PropTypes.shape({
    code: PropTypes.string,
  }),
  setData: PropTypes.func.isRequired,
  setCurrentTracking: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  variables: PropTypes.objectOf(PropTypes.any).isRequired,
  setVariables: PropTypes.func.isRequired,
};

TrackingForm.defaultProps = {
  initialValues: {},
};

export default memo(TrackingForm);
