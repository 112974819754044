const AWAITING_COLLECT = 'aguardando coleta';
const IN_TRANSIT = 'em trânsito';
const AVAILABLE_FOR_PICKUP = 'disponível para retirada';
const OUT_FOR_DELIVERY = 'saída para entrega';
const DELIVERED = 'entregue';

const defaultStatusList = [
  AWAITING_COLLECT,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  DELIVERED
];
const pickupStatusList = [
  AWAITING_COLLECT,
  IN_TRANSIT,
  AVAILABLE_FOR_PICKUP,
  DELIVERED,
];

const titles = {
  [AWAITING_COLLECT]: 'A sua carga está aguardando coleta',
  [IN_TRANSIT]: 'A sua entrega está a caminho.',
  [AVAILABLE_FOR_PICKUP]: 'Entrega Disponível para Retirada',
  [OUT_FOR_DELIVERY]: 'Saída para Entrega',
  [DELIVERED]: 'Ótimo! A entrega chegou :)',
};

const titlesDelay = {
  [AWAITING_COLLECT]: 'A sua carga está aguardando coleta',
  [IN_TRANSIT]:
    'Estamos verificando com a transportadora o(s) motivo(s) do atraso e estaremos atualizando o rastreamento.',
  [AVAILABLE_FOR_PICKUP]: 'Entrega Disponível para Retirada',
  [OUT_FOR_DELIVERY]: 'Saída para Entrega',
  [DELIVERED]: 'Ótimo! A entrega chegou :)',
};

const labels = {
  [AWAITING_COLLECT]: 'Previsão de coleta',
  [IN_TRANSIT]: 'Previsão de entrega',
  [AVAILABLE_FOR_PICKUP]: 'Previsão de entrega',
  [OUT_FOR_DELIVERY]: 'Previsão de entrega',
  [DELIVERED]: 'Entregue em',
};

const isDelay = statusDetail => statusDetail === 'Fora do prazo';

const useTrackingStatus = ({
  status,
  statusDetail,
  deliveredAt,
  estimateDelivery,
  collectAt,
  deliveryType
}) => {
  const statusList = 
    deliveryType === 'Ponto de retirada' ? pickupStatusList : defaultStatusList;
  const statusLowerCase = status.toLowerCase();
  const statusIndex = statusList.indexOf(statusLowerCase);
  const title = isDelay(statusDetail)
    ? titlesDelay[statusLowerCase]
    : titles[statusLowerCase];
  const label = labels[statusLowerCase];
  const dates = {
    [AWAITING_COLLECT]: collectAt,
    [IN_TRANSIT]: estimateDelivery,
    [OUT_FOR_DELIVERY]: estimateDelivery,
    [AVAILABLE_FOR_PICKUP]: null,
    [DELIVERED]: deliveredAt,
  };
  const date = dates[statusLowerCase];

  return {
    title,
    label,
    date,
    statusList,
    statusIndex,
  };
};

export default useTrackingStatus;
