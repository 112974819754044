import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Category,
  Description,
  InsertDriveFile,
  Layers,
  LocalAtm,
  LocalShipping,
} from 'styled-icons/material';
import SectionTitle from '../SectionTitle';
import { PRIMARY } from '../../utils/colors';
import Icon from '../Icon';

const TrackingDelivery = ({
  code,
  shipping_carrier_order_id: shippingCarrierOrderId,
  invoice_number: invoiceNumber,
  volumes_weight: weight,
  volumes_count: quantity,
  freight_type: type,
}) => (
  <>
    <SectionTitle size="small" color={PRIMARY}>
      Dados da remessa
    </SectionTitle>
    <p className="d-flex">
      <Icon
        icon={InsertDriveFile}
        size="24px"
        color="#343a40"
        margin="0 6px 0 0"
      />
      {`Pedido: ${code}`}
    </p>
    {shippingCarrierOrderId && (
      <p className="d-flex">
        <Icon
          icon={LocalShipping}
          size="24px"
          color="#343a40"
          margin="0 6px 0 0"
        />
        {`Código do Objeto: ${shippingCarrierOrderId}`}
      </p>
    )}
    <p className="d-flex">
      <Icon icon={Description} size="24px" color="#343a40" margin="0 6px 0 0" />
      {`Nota fiscal: ${invoiceNumber || 'Declaração de conteúdo'}`}
    </p>
    <p className="d-flex">
      <Icon icon={Layers} size="24px" color="#343a40" margin="0 6px 0 0" />
      {`Peso: ${weight}kg`}
    </p>
    <p className="d-flex">
      <Icon icon={Category} size="24px" color="#343a40" margin="0 6px 0 0" />
      {`Volume${quantity > 1 ? 's' : ''}: ${quantity}`}
    </p>
    <p className="d-flex">
      <Icon icon={LocalAtm} size="24px" color="#343a40" margin="0 6px 0 0" />
      {`Tipo de frete: ${type}`}
    </p>
  </>
);

TrackingDelivery.propTypes = {
  code: PropTypes.string.isRequired,
  shipping_carrier_order_id: PropTypes.string,
  invoice_number: PropTypes.string.isRequired,
  volumes_weight: PropTypes.number.isRequired,
  volumes_count: PropTypes.number.isRequired,
  freight_type: PropTypes.string.isRequired,
};

TrackingDelivery.defaultProps = {
  shipping_carrier_order_id: null
};

export default memo(TrackingDelivery);
