import gql from 'graphql-tag';

export default gql`
  query fetchTracking(
    $code: String
    $invoice_number: String
    $document: String
  ) {
    fetchTracking(
      code: $code
      invoice_number: $invoice_number
      document: $document
    ) {
      code
      shipping_carrier_order_id
      invoice_number
      status
      status_detail
      freight_type
      volumes_count
      volumes_weight
      collect_at
      estimated_delivery_at
      delivered_at
      previous_delivery_expire_at
      matrix_name
      delivery_type
      sender {
        name
        business_name
        document
        address {
          city {
            name
            state
          }
        }
      }
      recipient {
        name
        document
        address {
          zipcode
          street
          number
          complement
          district
          city {
            name
            state
          }
        }
      }
      history {
        created_at
        code
        event
        comments
        maps_link
        receipt_link
      }
    }
  }
`;
