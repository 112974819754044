import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from 'reactstrap';
import useTrackingStatus from '../../hooks/useTrackingStatus';

const TrackingCard = ({ data, currentTracking, setCurrentTracking }) => {
  const { title, label, date } = useTrackingStatus({
    status: data.status,
    statusDetail: data.status_detail,
    deliveredAt: data.delivered_at,
    estimateDelivery: data.estimated_delivery_at,
    collectAt: data.collect_at,
  });

  return (
    <Col>
      <Card>
        <CardHeader tag="h5">{`Pedido #${data.code}`}</CardHeader>
        <CardBody outline>
          <CardTitle>{title || data.TrackingCardstatus}</CardTitle>
          {title && date && <CardSubtitle>{`${label}: ${date}`}</CardSubtitle>}
          <CardText />
          <Button
            disabled={currentTracking && currentTracking.code === data.code}
            onClick={() => setCurrentTracking(data)}
          >
            Visualizar
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

TrackingCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  currentTracking: PropTypes.objectOf(PropTypes.any).isRequired,
  setCurrentTracking: PropTypes.func.isRequired,
};

export default TrackingCard;
