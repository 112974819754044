import styled from 'styled-components';
import { PRIMARY } from '../../utils/colors';

const uncheckedColor = '#e9ecef';

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    background: ${({ checked, hasmorecheckeds, last, direction }) => {
      const finalDirection = direction === 'vertical' ? 'bottom' : 'right';

      if (checked && (last || hasmorecheckeds)) {
        return `linear-gradient(to ${finalDirection}, ${PRIMARY} 0%, ${PRIMARY} 100%);`;
      }

      if (checked && !hasmorecheckeds) {
        return `linear-gradient(to ${finalDirection}, ${PRIMARY} 0%,${PRIMARY} 50%,${uncheckedColor} 51%,${uncheckedColor} 100%);`;
      }

      return `linear-gradient(to ${finalDirection}, ${uncheckedColor} 0%, ${uncheckedColor} 100%);`;
    }}
    position: absolute;
    z-index: 0;

    ${({ direction }) => {
      switch (direction) {
        case 'vertical':
          return `
            width: 3px;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0.5rem;
          `;
        default:
          return `
            height: 3px;
            width: 100%;
            right: 0;
            left: 0;
            top: -0.5rem;       
          `;
      }
    }}
  }

  &:after {
    ${({ checked }) => checked && `content: '✓';`}
    ${({ checked }) => !checked && `content: '';`}
    font-size: 0.8rem;
    color: white;
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 3px solid ${({ checked }) => (checked ? PRIMARY : uncheckedColor)};
    background-color: ${({ checked }) => (checked ? PRIMARY : 'white')};
    z-index: 1;
    margin: auto;

    ${({ direction }) => {
      switch (direction) {
        case 'vertical':
          return `
            top: 0;
            bottom: 0;
            left: 0.15rem;
            line-height: .6rem;
          `;
        default:
          return `
            right: 0;
            left: 0;
            top: -0.85rem;
            line-height: 0.7rem;
          `;
      }
    }}
  }
`;
