import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import Step from '../Timeline/Step';
import Marker from '../Timeline/Marker';
import { PRIMARY } from '../../utils/colors';
import SectionTitle from '../SectionTitle';

const TrackingHistory = ({ history }) => (
  <Container className="mt-5 pt-3 pb-3">
    <Row>
      <Col>
        <SectionTitle size="small" color={PRIMARY}>
          Histórico de viagem
        </SectionTitle>
      </Col>
    </Row>

    {!history.length && (
      <Row>
        <Col>
          <p>Nenhum histórico até o momento</p>
        </Col>
      </Row>
    )}

    {history.map(
      ({
        created_at: createdAt,
        event,
        comments,
        maps_link: mapsLink,
        receipt_link: receiptLink,
      }) => {
        const [date, time] = createdAt.split(' ');
        const [y, m, d] = date.split('-');

        return (
          <Row key={createdAt}>
            <Col xs="auto" className="d-flex flex-row align-items-center">
              <Step checked className="mr-2 text-center">
                <small>
                  <strong>{`${d}/${m}/${y}`}</strong>
                  <br />
                  {`às ${time.slice(0, 5)}`}
                </small>
              </Step>
              <Marker checked hasmorecheckeds direction="vertical" />
            </Col>
            <Col className="pt-2 pb-2 ml-2">
              <h4 className="mb-0">{event}</h4>
              <small style={{ display: 'block' }}>{comments}</small>
              {mapsLink && (
                <a
                  href={mapsLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: 8, color: '#D6008F' }}
                >
                  Ver localização
                </a>
              )}
              {receiptLink && (
                <a
                  href={receiptLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#D6008F' }}
                >
                  Ver foto
                </a>
              )}
            </Col>
          </Row>
        );
      }
    )}
  </Container>
);

TrackingHistory.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      event: PropTypes.string,
      comments: PropTypes.string,
      code: PropTypes.number,
      maps_link: PropTypes.string,
      receipt_link: PropTypes.string,
    })
  ),
};

TrackingHistory.defaultProps = {
  history: [],
};

export default memo(TrackingHistory);
