import React from 'react';
import styled from 'styled-components';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { TEXT } from '../../utils/colors';

const Button = styled.button`
  border: 0;
  border-radius: 100%;
  padding: 0 0.25rem;
  text-align: center;
  box-sizing: border-box;
  background-color: ${TEXT};
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  margin-left: 0.25rem;
`;

const Popover = styled(UncontrolledPopover)`
  .popover-inner {
    background-color: #000;
    min-width: 390px;
    border-radius: 2px;
  }

  .popover-body {
    color: white;
  }

  .arrow::after {
    border-bottom-color: #000;
    border-top-color: #000;
  }
`;

const TrackingStatusInfo = () => (
  <>
    <Button id="btnHelp">?</Button>
    <Popover trigger="focus" placement="bottom" target="btnHelp">
      <PopoverBody>
        Este é um prazo médio informado pela transportadora,
        <br />
        este prazo pode variar para menor ou maior
        <br />
        dependendo das condições de estrada, clima, situações
        <br />
        de fiscalização entre outros eventos que possam
        <br />
        ocorrer no trajeto desde a origem até o destino.
      </PopoverBody>
    </Popover>
  </>
);

export default TrackingStatusInfo;
