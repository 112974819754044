import React from 'react';
import PropTypes from 'prop-types';
import useAlert, { ALERT_TYPES } from '../../hooks/useAlert';

const TrackingError = ({ variables }) => {
  const initialValuesAlert = {
    message: 'Nenhuma informação encontrada',
    color: ALERT_TYPES.error,
    isOpen: true,
  };

  const { Alert } = useAlert(initialValuesAlert, false);

  if (!variables || Object.keys(variables).length === 0) {
    return <div className="pt-5 pb-5 text-center">&nbsp;</div>;
  }

  return (
    <div
      className="pt-5 pb-5 text-center"
      style={{ width: '80vw', maxWidth: '300px', margin: 'auto' }}
    >
      <Alert />
    </div>
  );
};

TrackingError.propTypes = {
  variables: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TrackingError;
