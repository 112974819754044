import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { formatToCPFOrCNPJ, isCPF } from 'brazilian-values';

const TrackingPerson = ({ name, businessName, document }) => {
  return (
    <>
      {name && <p>{name}</p>}
      {businessName && <p>{`Razão Social: ${businessName}`}</p>}
      <p>
        {`${isCPF(document) ? 'CPF' : 'CNPJ'}: ${formatToCPFOrCNPJ(document)}`}
      </p>
    </>
  );
};

TrackingPerson.propTypes = {
  name: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
};

export default memo(TrackingPerson);
